
require.register("youtube-player/dist/eventNames.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "youtube-player");
  (function() {
    'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});


/**
 * @see https://developers.google.com/youtube/iframe_api_reference#Events
 * `volumeChange` is not officially supported but seems to work
 * it emits an object: `{volume: 82.6923076923077, muted: false}`
 */
exports.default = ['ready', 'stateChange', 'playbackQualityChange', 'playbackRateChange', 'error', 'apiChange', 'volumeChange'];
module.exports = exports['default'];
  })();
});