
require.register("youtube-player/dist/constants/PlayerStates.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "youtube-player");
  (function() {
    "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  BUFFERING: 3,
  ENDED: 0,
  PAUSED: 2,
  PLAYING: 1,
  UNSTARTED: -1,
  VIDEO_CUED: 5
};
module.exports = exports["default"];
  })();
});